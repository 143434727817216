import { css, cx } from '@emotion/css';
import { Card, CardHeader, Theme, Typography } from '@mui/material';
import { useHover } from '@uidotdev/usehooks';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { BaseTooltip } from '@/components/floating/BaseTooltip';
import { useStyles } from '@/hooks/useStyles';
import { Message } from '@/models/ChatModel';
import { userFormattedName } from '@/models/UserModel.ts';
import { Queries } from '@/queries/Queries';
import { toDateTime } from '@/utils/dateUtils.ts';

type MessageContentType = {
  message: Message;
  className?: string;
};

export const MessageContent = ({ message, className }: MessageContentType) => {
  const currentUser = Queries.practitioner.usePractitioner({});
  const { t } = useTranslation();
  const [ref, hovering] = useHover();
  const isSender = message.sender.id === currentUser.data?.id;
  const updateMessage = Queries.chat.useUpdateMessage();

  const isForStaffOnly = message.visibility === 'practitioners';
  const styles = useStyles(makeStyle, isForStaffOnly, isSender);

  const timeSent = toDateTime(message.createdAt).toLocaleString(
    DateTime.TIME_SIMPLE,
  );

  const deleteHandler = () => {
    updateMessage.mutate({
      patientId: message.subject,
      messageId: message.id,
      status: 'stopped',
    });
  };

  const senderName = () => {
    if (isSender) {
      return t('messaging.me');
    }
    return userFormattedName(
      message.sender.givenName,
      message.sender.familyName,
    );
  };
  return (
    <div className={styles.container} ref={ref}>
      {isSender && (
        <div className={styles.delete}>
          {hovering && <Icons.trash onClick={deleteHandler}></Icons.trash>}
        </div>
      )}
      <Card elevation={0} className={cx(styles.card, className)}>
        <CardHeader
          className={styles.reducedPadding}
          title={
            <>
              <Typography className={styles.title}>{senderName()}</Typography>
              <Typography variant="caption">{timeSent}</Typography>
            </>
          }
        />

        {message.contentText && (
          <Typography variant="body" className={styles.contentText}>
            {message.contentText}
          </Typography>
        )}
        {message.attachment?.map((attachment, i) => (
          <BaseTooltip
            type="hover"
            placement="top"
            content={attachment.name}
            role="tooltip"
            key={i}
          >
            <IconButton
              placement="left"
              buttonType="link"
              icon={Icons.download}
              textType="body"
              iconClassname={styles.downloadIcon}
              onClick={() => window.open(attachment.url, '_blank')}
              className={styles.downloadSection}
            >
              {attachment.name}
            </IconButton>
          </BaseTooltip>
        ))}
      </Card>
    </div>
  );
};

const makeStyle = (
  theme: Theme,
  practitionerMessage: boolean,
  isSender: boolean,
) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${isSender ? 'flex-end' : 'flex-start'};
  `,
  card: css`
    display: flex;
    flex-direction: column;
    width: 85%;
    background-color: ${practitionerMessage
      ? theme.palette.green.contrastText
      : theme.palette.grey[50]};
    border-radius: ${isSender
      ? theme.spacing(4, 4, 0, 4)
      : theme.spacing(0, 4, 4, 4)};
    margin: ${theme.spacing(4)};
    padding: ${theme.spacing(4)};
    gap: ${theme.spacing(4)};
  `,
  delete: css`
    cursor: pointer;
  `,
  title: css`
    font-weight: bold;
    margin-right: ${theme.spacing(4)};
  `,
  time: css`
    color: ${theme.palette.text.secondary};
  `,
  reducedPadding: css`
    padding: 0 !important;
  `,
  downloadIcon: css`
    border-bottom: rgba(0, 0, 0, 0.5);
  `,
  downloadSection: css`
    max-width: 100%;
    padding: 0;
  `,
  contentText: css`
    white-space: pre-wrap;
    max-width: 100%;
  `,
});
