import { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type SidePeekBodyProps = PropsWithChildren<{
  className?: string;
}>;

const SidePeekFooter = ({ className, children }: SidePeekBodyProps) => {
  const styles = useStyles(makeStyles);
  return <div className={cx(styles.actions, className)}>{children}</div>;
};

export default SidePeekFooter;

const makeStyles = (theme: Theme) => ({
  actions: css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    flex: 0 1;
    column-gap: ${theme.spacing(8)};
  `,
});
