import { ChipOwnProps } from '@mui/material';
import { DecoderFunction, boolean, string } from 'typescript-json-decoder';

import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

type ConditionName = 'diabetes';
export type ConditionType = 'type1' | 'type2' | 'gestational';
export type MedicationSchema = 'noInsulin' | 'insulinPen' | 'insulinPump';

export type Condition = {
  periodStart: string;
  periodEnd?: string;
  name: ConditionName;
  type: ConditionType;
  medicationSchema: MedicationSchema;
  termDate?: string;
  isBillable: boolean;
};

export const conditionerDecoder: DecoderFunction<Condition> = recordWithContext(
  'Condition',
  {
    periodStart: string,
    periodEnd: nullOrUndef(string),
    name: stringUnion('diabetes'),
    type: stringUnion('type1', 'type2', 'gestational'),
    medicationSchema: stringUnion('noInsulin', 'insulinPen', 'insulinPump'),
    termDate: nullOrUndef(string),
    isBillable: boolean,
  },
);

export const diabetesTypeTranslationMapping = (
  type: ConditionType,
  insulin: MedicationSchema,
): string => {
  switch (true) {
    case type === 'type1':
      return 'models.diabetesType.type1';
    case type === 'type2':
      return 'models.diabetesType.type2';
    case type === 'gestational' && insulin === 'noInsulin':
      return 'models.diabetesType.gestationalNoInsulin';
    default:
      return 'models.diabetesType.gestationalWithInsulin';
  }
};

export const medicationSchemaTranslationMapping = (
  schema: MedicationSchema,
): string => {
  switch (schema) {
    case 'noInsulin':
      return 'models.medicationSchema.noInsulin';
    case 'insulinPen':
      return 'models.medicationSchema.insulinPen';
    case 'insulinPump':
      return 'models.medicationSchema.insulinPump';
  }
};

export const diabetesTypeColorMapping = (
  type: ConditionType,
  insulin: MedicationSchema,
): ChipOwnProps['color'] => {
  switch (true) {
    case type === 'type1':
      return 'orange';
    case type === 'type2':
      return 'blue';
    case type === 'gestational' && insulin === 'noInsulin':
      return 'purple';
    default:
      return 'pink';
  }
};
