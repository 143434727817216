import React from 'react';

import { css, cx } from '@emotion/css';
import { PaletteColor, Theme } from '@mui/material';
import { Group } from '@visx/group';

import { useStyles } from '@/hooks/useStyles';
import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';

const DOT_RADIUS = 7;
const DOT_STROKE_WIDTH = 1;

export type SvgDotProps = {
  color: PaletteColor;
  radius?: number;
  content?: number | string;
  className?: string;
  left?: number;
  top?: number;
  classes?: {
    root?: string;
    circle?: string;
    text?: string;
  };
};

/**
 * Dot component for SVGs.
 *
 * Notification dot with an optional number.
 *
 * Example :
 * When badges are too close to each other, they are merged and a dot with number
 * of badges is displayed above the badge.
 */
export const SvgDot: React.FC<SvgDotProps> = ({
  content,
  color,
  className,
  classes,
  radius = DOT_RADIUS,
  left = 0,
  top = 0,
}) => {
  const styles = useStyles(makeStyles, color);

  return (
    <Group
      left={left}
      top={top}
      className={cx(styles.container, className, classes?.root)}
    >
      <circle r={radius} className={cx(styles.circle, classes?.circle)} />
      <SvgText
        variant="captionSmall"
        textAnchor="middle"
        verticalAnchor="middle"
        className={cx(styles.text, classes?.text)}
      >
        {content ?? ''}
      </SvgText>
    </Group>
  );
};

const makeStyles = (theme: Theme, color: PaletteColor) => ({
  container: css``,
  circle: css({
    fill: color.contrastText,
    stroke: theme.palette.common.white,
    strokeWidth: DOT_STROKE_WIDTH,
  }),
  text: css({
    fill: color.main,
  }),
});
