import {
  DecoderFunction,
  literal,
  number,
  record,
  string,
  union,
} from 'typescript-json-decoder';

import { decodeId, nullOrUndef } from '@/utils/decoderUtils.ts';

export type BasePumpEvent = {
  id: string;
  date: string;
};

export type PumpCartridgeFilledEvent = BasePumpEvent & {
  category: 'cartridge_filled';
  insulinDisplay?: number;
  insulinActual?: number;
};

export type PumpTubingFilledEvent = BasePumpEvent & {
  category: 'tubing_filled';
  primeSize?: number;
};

export type PumpCannulaFilledEvent = BasePumpEvent & {
  category: 'cannula_filled';
  primeSize?: number;
};

export type PumpEvent =
  | PumpCartridgeFilledEvent
  | PumpTubingFilledEvent
  | PumpCannulaFilledEvent;

export const pumpEventDecoder: DecoderFunction<PumpEvent> = union(
  record({
    id: decodeId,
    date: string,
    category: literal('cartridge_filled'),
    insulinDisplay: nullOrUndef(number),
    insulinActual: nullOrUndef(number),
  }),
  record({
    id: decodeId,
    date: string,
    category: literal('tubing_filled'),
    primeSize: nullOrUndef(number),
  }),
  record({
    id: decodeId,
    date: string,
    category: literal('cannula_filled'),
    primeSize: nullOrUndef(number),
  }),
);

export const getPumpEventTranslation = (
  category: PumpEvent['category'],
): string => {
  switch (category) {
    case 'cannula_filled':
      return 'models.pumpEventCategory.cannulaFilled';
    case 'cartridge_filled':
      return 'models.pumpEventCategory.cartridgeFilled';
    case 'tubing_filled':
      return 'models.pumpEventCategory.tubingFilled';
  }
};
