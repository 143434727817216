import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { BottomBar } from '@/components/navigation/BottomBar.tsx';
import { NavItem } from '@/components/navigation/NavItem';
import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useStyles';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';
import { ScreenMax, ScreenMin } from '@/theme/responsive.ts';

type HealthProLayoutPageProps = LayoutProps;

export const HealthProLayout: React.FC<HealthProLayoutPageProps> = props => {
  const styles = useStyles(makeStyles);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  return (
    <div
      id="practitioner-layout"
      lang={i18n.language}
      className={styles.container}
    >
      <SideBar home="/patients" className={styles.sidebar}>
        <NavItem
          id="patient-list-nav-item"
          to="/patients"
          isCurrent={
            location.pathname === '/' ||
            location.pathname.startsWith('/patients')
          }
          tooltip={t('navbar.myPatients')}
        />
      </SideBar>
      <main id="practitioner-layout-main" className={styles.content}>
        <RenderLayoutChildren {...props} />
      </main>
      <BottomBar home="/patients" className={styles.bottomBar}>
        <NavItem
          id="patient-list-nav-item"
          to="/patients"
          isCurrent={
            location.pathname === '/' ||
            location.pathname.startsWith('/patients')
          }
          tooltip={t('navbar.myPatients')}
        />
      </BottomBar>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: ${theme.palette.background.default};
  `,
  sidebar: css`
    ${ScreenMax.SM} {
      display: none;
    }
  `,
  bottomBar: css`
    ${ScreenMin.SM} {
      display: none;
    }
  `,
  content: css`
    flex: 1 1;
    overflow-x: clip;

    ${ScreenMin.SM} {
      margin-left: ${theme.spacing(30)};
    }
    ${ScreenMax.SM} {
      margin-bottom: ${theme.spacing(30)};
    }
  `,
});
