import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { ScreenMax } from '@/theme/responsive.ts';

export type SidePeekProps = {
  className?: string;
  children: React.ReactNode;
};

export const SidePeek: React.FC<SidePeekProps> = ({ children }) => {
  const styles = useStyles(makeStyles);

  return <div className={styles.sidePeekContainer}>{children}</div>;
};

const makeStyles = (theme: Theme) => ({
  sidePeekContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: min(500px, 95vw);
    height: 100vh;
    position: fixed;
    right: 0;
    z-index: ${theme.zIndex.modal};
    overflow: clip !important;

    background: ${theme.palette.common.white};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    border-radius: ${theme.spacing(16)} 0px 0px ${theme.spacing(16)};

    ${ScreenMax.SM} {
      border-radius: 0;
      width: 100vw;
      box-shadow: none;
    }
  `,
});
