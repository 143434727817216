import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { Logo } from '@/components/navigation/Logo.tsx';
import { UserMenu } from '@/components/navigation/UserMenu';
import { useStyles } from '@/hooks/useStyles';

export type BottomBarProps = PropsWithChildren<{
  home: string;
  className?: string;
}>;

export const BottomBar: React.FC<BottomBarProps> = ({
  home,
  className,
  children,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <nav className={cx(styles.container, className)} id="main-mobile-navbar">
      <Logo to={home} />
      {children}
      <UserMenu />
    </nav>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${theme.spacing(30)};
    width: 100%;
    z-index: ${theme.zIndex.appBar};
    border-top-width: 1px;
    border: ${theme.palette.common.white} solid;
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(0, 8)};
    position: fixed;
    bottom: 0;
  `,
});
