import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { Table, TableContainer, Theme, Typography } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { BGMData } from '@/models/BGMLogbook';
import { getFoodMealTranslation } from '@/models/diabetes/FoodModel.ts';
import { BackToTop } from '@/uiKit/molecules/BackToTop.tsx';

import { BGMTableBody } from './TableBody/BGMTableBody';
import { PrettyComment } from './TableBody/Comments/PrettyComment';
import { GlycemiaBadges } from './TableBody/GlycemiaBadges';
import { Insulin } from './TableBody/Insulin';
import { PrettyDate } from './TableBody/PrettyDate';
import { BGMTableHeader } from './TableHeader/BGMTableHeader';
import { HeaderWithTooltip } from './TableHeader/HeaderWithTooltip';

const columnHelper = createColumnHelper<BGMData>();
const duplicateHeader = (
  patientId: string,
  groupHeader: 'breakfast' | 'lunch' | 'dinner',
  className: string,
  t: TFunction<'translation', undefined>,
) => {
  return columnHelper.group({
    id: groupHeader,
    header: () => (
      <Typography variant="body" className={className}>
        {t(getFoodMealTranslation(groupHeader))}
      </Typography>
    ),
    columns: [
      columnHelper.accessor(`${groupHeader}.bloodSugarBefore`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.glycemiaBefore"
            titleLocaleString="bgmLogBook.glycemiaBeforeHeader"
          />
        ),
        cell: info => (
          <GlycemiaBadges
            patientId={patientId}
            data={info.getValue()}
            meal={groupHeader}
            timing="before"
          />
        ),
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.fastInsulin`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.fastInsulin"
            titleLocaleString="bgmLogBook.fastInsulinHeader"
          />
        ),
        cell: info => <Insulin data={info.getValue()} />,
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.bloodSugarAfter`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.glycemiaAfter"
            titleLocaleString="bgmLogBook.glycemiaAfterHeader"
          />
        ),
        cell: info => (
          <GlycemiaBadges
            patientId={patientId}
            data={info.getValue()}
            meal={groupHeader}
            timing="after"
          />
        ),
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.slowInsulin`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.slowInsulin"
            titleLocaleString="bgmLogBook.slowInsulinHeader"
          />
        ),
        cell: info => <Insulin data={info.getValue()} />,
        size: 50,
      }),
    ],
  });
};

type BGMContainerBodyProps = {
  patientId: string;
  data: BGMData[];
  loadMore?: () => void;
};

export const BGMContainerBody: React.FC<BGMContainerBodyProps> = ({
  patientId,
  data,
  loadMore,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: () => (
          <Typography variant="body" className={styles.headerItem}>
            {t('bgmLogBook.date')}
          </Typography>
        ),
        cell: info => <PrettyDate date={info.getValue()} />,
        size: 90,
      }),
      duplicateHeader(patientId, 'breakfast', styles.headerItem, t),
      duplicateHeader(patientId, 'lunch', styles.headerItem, t),
      duplicateHeader(patientId, 'dinner', styles.headerItem, t),
      columnHelper.group({
        id: 'night',
        header: () => (
          <Typography variant="body" className={styles.headerItem}>
            {t('bgmLogBook.nightTime')}
          </Typography>
        ),
        columns: [
          columnHelper.accessor('night.bloodSugar', {
            header: () => (
              <Typography variant="body" className={styles.headerItem}>
                {t('bgmLogBook.glycemia')}
              </Typography>
            ),
            cell: info => (
              <GlycemiaBadges
                patientId={patientId}
                data={info.getValue()}
                meal="lunch"
                timing="after"
              />
            ),
            size: 100,
          }),
          columnHelper.accessor('night.slowInsulin', {
            header: () => (
              <HeaderWithTooltip
                tootlipContent="bgmLogBook.slowInsulin"
                titleLocaleString="bgmLogBook.slowInsulinHeader"
              />
            ),
            cell: info => <Insulin data={info.getValue()} />,
            size: 50,
          }),
        ],
      }),
      columnHelper.accessor('comments', {
        header: () => (
          <Typography variant="body" className={styles.headerItem}>
            {t('bgmLogBook.comment')}
          </Typography>
        ),
        cell: info => <PrettyComment comment={info.getValue()} />,
        size: 300,
      }),
    ],
    [styles.headerItem, t],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <BackToTop />
      <TableContainer className={styles.container}>
        <Table
          aria-label="a dense table"
          className={styles.table}
          size="small"
          stickyHeader
        >
          <BGMTableHeader table={table} className={styles.header} />
          <BGMTableBody table={table} loadMore={loadMore} />
        </Table>
      </TableContainer>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    border-radius: ${theme.spacing(6)};
    overflow: scroll;
  `,
  scrollBar: css`
    height: 40vh;
  `,
  table: css`
    width: 100%;
    overflow: clip;
  `,
  header: css`
    top: 0;
    position: sticky;
  `,
  headerItem: css`
    color: ${theme.palette.violet.main};
  `,
});
