import {
  DecoderFunction,
  boolean,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import {
  decodeId,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils.ts';

/***********************
 *    Insulin types    *
 ***********************/

export type InsulinType = 'long' | 'short';
export type InsulinReason = 'bolus' | 'basal';
export type InsulinPeriod = 'breakfast' | 'lunch' | 'dinner';
export type InsulinMethod = 'pen' | 'pump';

export type Insulin = {
  id: number;
  patientId: string;
  date: string;
  deviceId?: string;
  deviceType?: DeviceType;

  quantity: number;
  type: InsulinType;
  period: InsulinPeriod;
  reason: InsulinReason;
  method: InsulinMethod;
  correction: boolean;
  bolusSettings?: BolusSettings;
};

export type DiscreteBasalInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'long';
  reason: 'basal';
};

export type ContinuousBasalInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'short';
  reason: 'basal';
};

export type BolusInsulin = Omit<Insulin, 'type' | 'reason'> & {
  type: 'short';
  reason: 'bolus';
};

export type SplitInsulinData = {
  continuous: ContinuousBasalInsulin[];
  discrete: DiscreteBasalInsulin[];
  bolus: BolusInsulin[];
};

export type ManualBolusSettings = {
  type: 'manual';
  bolusId: number;
  foodInsulin: number;
};

export type CarbsBolusSettings = {
  type: 'carbs';
  bolusId: number;
  foodInsulin: number;
  carbs?: number;
  carbsRatio?: number;
  iob?: number;
};

export type CorrectionBolusSettings = {
  type: 'correction';
  bolusId: number;
  correctionInsulin: number;
  glycemia?: number;
  insulinSensitivity?: number;
  targetGlycemia?: number;
  iob?: number;
};

export type CarbsCorrectionBolusSettings = {
  type: 'carbs_and_correction';
  bolusId: number;
  foodInsulin: number;
  carbs?: number;
  carbsRatio?: number;
  correctionInsulin: number;
  glycemia?: number;
  insulinSensitivity?: number;
  targetGlycemia?: number;
  iob?: number;
};

export type BolusSettings =
  | ManualBolusSettings
  | CarbsBolusSettings
  | CorrectionBolusSettings
  | CarbsCorrectionBolusSettings;

/***********************
 *   Insulin decoders  *
 ***********************/

const rawBolusSettingsDecoder = recordWithContext('BolusSettings', {
  bolusId: field('bolus_id', number),
  foodInsulinInputMethod: field(
    'food_insulin_input_method',
    stringUnion('manual', 'carbs', 'none'),
  ),
  foodInsulin: field('food_insulin', number),
  correctionInsulinInputMethod: field(
    'correction_insulin_input_method',
    stringUnion('blood_glucose', 'none'),
  ),
  correctionInsulin: field('correction_insulin', number),
  carbs: field('carbs', nullOrUndef(number)),
  carbsRatio: field('carbs_ratio', nullOrUndef(number)),
  glycemia: field('glycemia', nullOrUndef(number)),
  insulinSensitivity: field('insulin_sensitivity', nullOrUndef(number)),
  targetGlycemia: field('target_glycemia', nullOrUndef(number)),
  iob: field('iob', nullOrUndef(number)),
});

export const decodeBolusSettings = (
  value: unknown,
): BolusSettings | undefined => {
  let rawBolusSettings;
  try {
    rawBolusSettings = rawBolusSettingsDecoder(value);
  } catch (_) {
    return undefined;
  }
  if (!rawBolusSettings) {
    return undefined;
  }
  if (rawBolusSettings.foodInsulinInputMethod === 'manual') {
    return {
      type: 'manual',
      bolusId: rawBolusSettings.bolusId,
      foodInsulin: rawBolusSettings.foodInsulin,
    } satisfies ManualBolusSettings;
  }
  const carbsSettings =
    rawBolusSettings.foodInsulinInputMethod === 'carbs'
      ? {
          bolusId: rawBolusSettings.bolusId,
          foodInsulin: rawBolusSettings.foodInsulin,
          carbs: rawBolusSettings.carbs,
          carbsRatio: rawBolusSettings.carbsRatio,
          iob: rawBolusSettings.iob,
        }
      : undefined;
  const correctionSettings =
    rawBolusSettings.correctionInsulinInputMethod === 'blood_glucose'
      ? {
          bolusId: rawBolusSettings.bolusId,
          correctionInsulin: rawBolusSettings.correctionInsulin,
          glycemia: rawBolusSettings.glycemia,
          insulinSensitivity: rawBolusSettings.insulinSensitivity,
          targetGlycemia: rawBolusSettings.targetGlycemia,
          iob: rawBolusSettings.iob,
        }
      : undefined;
  if (carbsSettings && correctionSettings) {
    return {
      type: 'carbs_and_correction',
      ...carbsSettings,
      ...correctionSettings,
    };
  } else if (carbsSettings) {
    return {
      type: 'carbs',
      ...carbsSettings,
    };
  } else if (correctionSettings) {
    return {
      type: 'correction',
      ...correctionSettings,
    };
  }

  return undefined;
};

export const insulinDecoder: DecoderFunction<Insulin> = recordWithContext(
  'Insulin',
  {
    id: number,
    patientId: field('patient_id', string),
    date: string,
    deviceId: field('device_id', nullOrUndef(decodeId)),
    deviceType: field(
      'device_kind',
      nullOrUndef(stringUnion('pump', 'pen', 'reader', 'sensor', 'manual')),
    ),
    quantity: number,
    type: stringUnion<InsulinType>('long', 'short'),
    reason: stringUnion<InsulinReason>('bolus', 'basal'),
    period: stringUnion<InsulinPeriod>('breakfast', 'lunch', 'dinner'),
    method: stringUnion<InsulinMethod>('pen', 'pump'),
    correction: boolean,
    bolusSettings: field('bolus_settings', nullOrUndef(decodeBolusSettings)),
  },
);

/***********************
 *   Insulin helpers   *
 ***********************/

export const getInsulinTypeTranslation = (type: InsulinType): string => {
  switch (type) {
    case 'long':
      return 'models.insulinType.slow';
    case 'short':
      return 'models.insulinType.fast';
  }
};

export const getInsulinReasonTranslation = (reason: InsulinReason): string => {
  switch (reason) {
    case 'bolus':
      return 'models.insulinReason.bolus';
    case 'basal':
      return 'models.insulinReason.basal';
  }
};
