import React, { useId } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { useTranslation } from 'react-i18next';

import { splitGlycemiaData } from '@/business/GlycemiaLogic.ts';
import { useStyles } from '@/hooks/useStyles';
import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel.ts';
import { Glycemia } from '@/models/diabetes/GlycemiaModel.ts';
import { SvgLegendItem } from '@/uiKit/atoms/svg/SvgLegendItem.tsx';
import { ContinuousGlycemiaGraph } from '@/uiKit/molecules/graphs/GlycemiaGraph/ContinuousGlycemiaGraph.tsx';
import { DiscreteGlycemiaPoints } from '@/uiKit/molecules/graphs/GlycemiaGraph/DiscreteGlycemiaPoints.tsx';
import { GlycemiaGradient } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaGradient.tsx';
import { GlycemiaLegend } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaLegend.tsx';
import { GlycemiaThresholdsRect } from '@/uiKit/molecules/graphs/GlycemiaGraph/GlycemiaThresholdsRect.tsx';
import {
  SvgCoords,
  SvgLayout,
  SvgLegend,
  dayScale,
  zeroScale,
} from '@/utils/graphUtils.ts';

export type GlycemiaGraphProps = {
  date: string;
  glycemia: Glycemia[];
  glycemiaParameters: CGMGlycemiaParameters;
  legend?: SvgLegend;
  className?: string;
  isDexcom: boolean;
} & SvgLayout;

export type GlycemiaGraphLayout = {
  legend: SvgLayout;
  graph: SvgLayout;
  bottomLine: SvgCoords;
  verticalPadding: number;
  thresholds: SvgLayout;
};

const GlycemiaGraph_: React.FC<GlycemiaGraphProps> = ({
  date,
  glycemia,
  glycemiaParameters,
  legend,
  className,
  isDexcom,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const data = splitGlycemiaData(glycemia);
  const layout = getLayout(dim, legend);
  const gradientId = useId();
  const { t } = useTranslation();

  const max = Math.max(
    ...glycemia.map(glyc => glyc.value),
    glycemiaParameters.severeHyperglycemia,
  );

  const xScale = dayScale(layout.graph.width);
  const yScale = zeroScale(layout.graph.height - layout.verticalPadding, max);

  const legendContentWidth =
    layout.legend.width - (legend?.legendPadding.x ?? 0) * 2;

  return (
    <Group className={cx(styles.container, className)} {...dim}>
      <GlycemiaGradient
        id={gradientId}
        thresholds={glycemiaParameters}
        yScale={yScale}
      />
      <Line {...layout.bottomLine} className={styles.line} />
      {legend && (
        <GlycemiaLegend
          {...layout.legend}
          legendPadding={legend.legendPadding}
          label={
            <SvgLegendItem
              label={t('charts.glycemia.glycemia', {
                context: legendContentWidth < 90 ? 'short' : '',
              })}
              unit={
                legendContentWidth < 60 ? '' : t('charts.glycemia.glycemiaUnit')
              }
            />
          }
        />
      )}
      <GlycemiaThresholdsRect
        thresholds={glycemiaParameters}
        yScale={yScale}
        {...layout.thresholds}
      />
      <ContinuousGlycemiaGraph
        gradientId={gradientId}
        date={date}
        yScale={yScale}
        xScale={xScale}
        thresholds={glycemiaParameters}
        continuous={data.sensor}
        paddingTop={layout.verticalPadding}
        isDexcom={isDexcom}
        {...layout.graph}
      />
      <DiscreteGlycemiaPoints
        type={data.sensor.length > 0 ? 'compact' : 'normal'}
        discrete={data.reader}
        thresholds={glycemiaParameters}
        xScale={xScale}
        yScale={yScale}
        date={date}
        paddingTop={layout.verticalPadding}
        {...layout.graph}
      />
    </Group>
  );
};

export const GlycemiaGraph = React.memo(GlycemiaGraph_);

const getLayout = (
  dim: SvgLayout,
  legend: SvgLegend | undefined,
): GlycemiaGraphLayout => {
  const graphHorizontalPadding = dim.left;
  const graphVerticalPadding = 10;

  const left = legend ? legend.legendWidth : 0;
  const width = legend ? dim.width - legend.legendWidth : dim.width;

  return {
    legend: {
      top: 0,
      left: 0,
      width: legend?.legendWidth || 0,
      height: dim.height,
    },
    thresholds: {
      top: graphVerticalPadding,
      left,
      width,
      height: dim.height - graphVerticalPadding,
    },
    graph: {
      top: 0,
      left,
      width,
      height: dim.height,
    },
    bottomLine: {
      from: {
        x: 0,
        y: dim.height,
      },
      to: {
        x: dim.width + graphHorizontalPadding,
        y: dim.height,
      },
    },
    verticalPadding: graphVerticalPadding,
  };
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  line: css({
    stroke: theme.palette.grey[100],
    strokeWidth: 1,
  }),
});
