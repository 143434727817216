import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import { useStyles } from '@/hooks/useStyles';
import { BGMConcentrationType } from '@/models/BGMLogbook';
import { BaseGlycemiaParameters } from '@/models/DiabetesDataModel.ts';
import { ScreenMax } from '@/theme/responsive.ts';
import { Tag } from '@/uiKit/atoms/Tag.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';

export type GlycemiaBadgeProps = {
  glycemiaValue: BGMConcentrationType;
  thresholds: BaseGlycemiaParameters;
};

export const GlycemiaBadge: React.FC<GlycemiaBadgeProps> = ({
  glycemiaValue,
  thresholds,
}) => {
  const value = glycemiaValue.concentration;

  const styles = useStyles(makeStyles, value, thresholds);

  const tooltipClassName = glycemiaValue.date === ' ' ? styles.hide : '';
  const time = toDateTime(glycemiaValue.date).toLocaleString(
    DateTime.TIME_SIMPLE,
  );
  return (
    <Tooltip
      placement="top"
      content={time}
      animation={{ duration: 50 }}
      floaterClassName={tooltipClassName}
      hover={{ handleClose: null }}
    >
      <Tag className={styles.tag} label={value.toString()} />
    </Tooltip>
  );
};

const makeStyles = (
  theme: Theme,
  value: number,
  thresholds: BaseGlycemiaParameters,
) => ({
  tag: css({
    backgroundColor:
      value >= thresholds.severeHyperglycemia
        ? theme.palette.bgm.orangeDarkBackground
        : value >= thresholds.hyperglycemia
          ? theme.palette.bgm.orangeBackground
          : value < thresholds.severeHypoglycemia
            ? theme.palette.bgm.purpleBackground
            : value < thresholds.hypoglycemia
              ? theme.palette.bgm.redBackground
              : theme.palette.bgm.greenBackground,
    color:
      value <= thresholds.severeHypoglycemia
        ? theme.palette.common.white
        : theme.palette.text.primary,
    width: `calc(${theme.typography.caption.fontSize} * 3.4)`,
    [ScreenMax.SM]: {
      width: `calc(${theme.typography.caption.fontSize} * 2.5)`,
      ['&>*']: {
        padding: 0,
      },
    },
  }),
  hide: css`
    display: none;
  `,
});
