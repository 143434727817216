import { css } from '@emotion/css';
import { useFormContext } from 'react-hook-form';

import { useStyles } from '@/hooks/useStyles';
import { ConditionType, MedicationSchema } from '@/models/ConditionModel';
import { TelemonitoringCriteriaTranslationMap } from '@/models/TelemonitoringCriteriaModel.ts';
import { Queries } from '@/queries/Queries';

import { DropDown } from './ControlledDropDown';

type TelemonitoringCriteriaPickProps = {
  patientId?: string;
};

export const TelemonitoringCriteriaPick = ({
  patientId,
}: TelemonitoringCriteriaPickProps) => {
  const { data } = Queries.practitioner.useCarePlanDirectives({ patientId });
  const styles = useStyles(makeStyles);
  const { telemonitoringCriteriaMap, telemonitoringTierMap } = data || {};

  const context = useFormContext();
  const insulinType: MedicationSchema | undefined = context.watch(
    'condition.insulinType',
  );
  const diabetesType: ConditionType | undefined = context.watch(
    'condition.diabetesType',
  );

  if (
    !(
      insulinType &&
      diabetesType &&
      telemonitoringCriteriaMap &&
      telemonitoringTierMap
    )
  ) {
    return (
      <DropDown
        id="tls-criteria-pick"
        classes={{
          root: styles.container,
          dropdown: styles.dropdown,
          select: styles.select,
        }}
        fieldName="fakefield"
        choices={[]}
        disabled={true}
      />
    );
  }

  const criteriaChoiceByDiabetesType = telemonitoringCriteriaMap[diabetesType];
  const criteriaChoice =
    insulinType in criteriaChoiceByDiabetesType
      ? criteriaChoiceByDiabetesType[insulinType].map(criteria => ({
          value: criteria,
          translationKey: TelemonitoringCriteriaTranslationMap[criteria],
        }))
      : [];

  return (
    <DropDown
      id="tls-criteria-pick"
      classes={{
        root: styles.container,
        dropdown: styles.dropdown,
        select: styles.select,
      }}
      fieldName="carePlan.telemonitoringCriteria"
      choices={criteriaChoice}
      disabled={!diabetesType || !insulinType}
    />
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    overflow: clip;
    white-space: wrap;
  `,
  dropdown: css`
    flex: 1 1 0;
    min-width: 0;
  `,
  select: css`
    white-space: wrap !important;
  `,
});
