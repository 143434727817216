import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';

export type SideSheetHeaderProps = {
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  exit?: () => void;
  className?: string;
};

export const SidePeekHeader: React.FC<SideSheetHeaderProps> = ({
  exit,
  title,
  subtitle,
  className,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={cx(styles.container, className)}>
      {typeof title === 'string' ? (
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
      ) : (
        title
      )}
      {typeof subtitle === 'string' ? (
        <Typography variant="body">{subtitle}</Typography>
      ) : (
        subtitle
      )}
      <div className={styles.buttonContainer}>
        <Icons.closeIcon onClick={exit} className={styles.closeButton} />
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    padding-left: ${theme.spacing(4)};
    padding-right: ${theme.spacing(12)};
    padding-top: ${theme.spacing(8)};
    align-items: center;
    width: 100%;
    overflow: clip;
  `,
  closeButton: css`
    display: flex;
    width: 32px;
    height: 32px;
    justify-self: center;
    flex-shrink: 0;
    border-radius: ${theme.spacing(12)};
    background: ${theme.palette.background.default};
    padding: ${theme.spacing(4)};
    float: right;
    cursor: pointer;
  `,
  buttonContainer: css`
    margin-left: auto;
  `,
});
