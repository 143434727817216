import React, { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import { Glycemia } from '@/models/diabetes/GlycemiaModel.ts';
import { SvgDataTooltip } from '@/uiKit/atoms/svg/SvgDataTooltip.tsx';
import { formatNumber } from '@/utils/mathUtils.ts';

export type GlycemiaGraphTooltipProps = PropsWithChildren<{
  glycemia: Glycemia;
}>;

export const GlycemiaGraphTooltip: React.FC<GlycemiaGraphTooltipProps> = ({
  glycemia,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      svg
      placement="top"
      dismiss={false}
      autoOpen
      offset={0}
      content={
        <SvgDataTooltip
          content={{
            [t('charts.data')]: t('glycemia.title'),
            [t('charts.glucose')]: `${formatNumber(glycemia.value, 0)} mg/dL`,
          }}
          date={glycemia.date}
          inputMethod={glycemia.deviceType}
        />
      }
    >
      {children}
    </Tooltip>
  );
};

export type GlycemiaPointTooltipProps = PropsWithChildren<{
  glycemia: Glycemia;
  x?: number;
  y?: number;
}>;

export const GlycemiaPointTooltip: React.FC<GlycemiaPointTooltipProps> = ({
  glycemia,
  x,
  y,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      svg
      placement="top"
      relativeClientPoint
      clientPoint={{
        enabled: true,
        x: x ?? 0,
        y: y ?? 0,
      }}
      content={
        <SvgDataTooltip
          content={{
            [t('charts.data')]: t('glycemia.title'),
            [t('charts.glucose')]: `${formatNumber(glycemia.value, 0)} mg/dL`,
          }}
          date={glycemia.date}
          inputMethod={glycemia.deviceType}
        />
      }
    >
      {children}
    </Tooltip>
  );
};
