import { useContext, useLayoutEffect, useRef } from 'react';

import { css } from '@emotion/css';
import { DateTime } from 'luxon';

import { MessagesByStateContext } from '@/components/chat/ChatContext';
import { DateSeparator } from '@/components/chat/body/DateSeparator';
import { MessageContent } from '@/components/chat/body/MessageContent';
import { useStyles } from '@/hooks/useStyles.ts';
import { Message } from '@/models/ChatModel';
import { toDateTime } from '@/utils/dateUtils.ts';

type GroupedMessage = {
  sentDate: DateTime;
  messages: Message[];
};

export const Messages = () => {
  const styles = useStyles(makeStyle);
  const endOfMessageRef = useRef<HTMLDivElement>(null);
  const { messages } = useContext(MessagesByStateContext);

  useLayoutEffect(() => {
    if (endOfMessageRef?.current) {
      endOfMessageRef.current.scrollIntoView();
    }
  });

  const dateViewed: number[] = [];

  const res: GroupedMessage[] = Object.values(
    messages.reduce<GroupedMessage[]>((acc, curr) => {
      const sentDate = toDateTime(curr.createdAt).startOf('day');
      const dateValue = sentDate.startOf('day').toMillis();
      if (dateViewed.includes(dateValue)) {
        const existing = acc.find(e => e.sentDate.toMillis() === dateValue);
        existing?.messages.push(curr);
      } else {
        acc.push({ sentDate, messages: [curr] });
        dateViewed.push(dateValue);
      }

      return acc;
    }, []),
  );

  return (
    <div className={styles.container}>
      {messages &&
        res.map(groupedMessage => (
          <>
            <DateSeparator
              key={groupedMessage.sentDate.toISO()}
              date={groupedMessage.sentDate}
            />
            {groupedMessage.messages.map(message => (
              <MessageContent key={message.id} message={message} />
            ))}
          </>
        ))}
      <div ref={endOfMessageRef} />
    </div>
  );
};

const makeStyle = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
});
