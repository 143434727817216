import {
  DecoderFunction,
  nullable,
  record,
  string,
} from 'typescript-json-decoder';

import { FileToUpload } from '@/models/FileModel';
import {
  forcedArray,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type ChatFilterParametersType = 'all' | 'practitioners';

export type Sender = {
  userType: 'practitioner' | 'patient';
  id: string;
  familyName: string;
  givenName: string;
};

export type Message = {
  id: string;
  visibility: ChatFilterParametersType;
  contentText: string | null;
  subject: string;
  createdAt: string;
  sender: Sender;
  attachment: { url: string; name: string }[];
};

export const senderDecoder: DecoderFunction<Sender> = record({
  userType: stringUnion('practitioner', 'patient'),
  id: string,
  familyName: string,
  givenName: string,
});

export const messageDecoder: DecoderFunction<Message> = recordWithContext(
  'Message',
  {
    id: string,
    visibility: stringUnion('all', 'practitioners'),
    contentText: nullable(string),
    subject: string,
    createdAt: string,
    sender: senderDecoder,
    attachment: forcedArray(record({ url: string, name: string })),
  },
);

export type CreateMessage = {
  status: string;
  visibility: ChatFilterParametersType;
  contentText?: string;
  contentAttachment?: FileToUpload[];
};

export type Status = 'stopped';
export type UpdateMessage = {
  status: Status;
};

export const updateMessageDecoder: DecoderFunction<UpdateMessage> =
  recordWithContext('UpdateMessage', {
    status: stringUnion('stopped'),
  });
