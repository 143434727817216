import React, { PropsWithChildren } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import { getActivityIntensityTranslation } from '@/models/diabetes/ActivityModel.ts';
import {
  getFoodMealTranslation,
  getFoodSizeTranslation,
} from '@/models/diabetes/FoodModel.ts';
import { getReportCategoryTranslation } from '@/models/diabetes/ReportModel.ts';
import { SvgDataListTooltip } from '@/uiKit/atoms/svg/SvgDataListTooltip';
import { SvgDataTooltipProps } from '@/uiKit/atoms/svg/SvgDataTooltip.tsx';
import { AdditionalDiabetesInfo } from '@/uiKit/molecules/graphs/AdditionalDiabetesInfoGraph/AdditionalDiabetesInfoUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

export const getAdditionalDiabetesInfoTooltipContent = (
  info: AdditionalDiabetesInfo,
  t: TFunction,
): SvgDataTooltipProps => {
  switch (info.type) {
    case 'food': {
      const food = info.data;
      return {
        content: {
          [t('charts.data')]: t('food.title'),
          [t('food.meal')]: t(getFoodMealTranslation(food.meal)),
          [t('food.carbs')]:
            `${formatNumber(food.carbs ?? 0, 1)} ${t('food.carbsUnit')}`,
          [t('food.size')]: t(getFoodSizeTranslation(food.size)),
          ...(food.comments ? { [t('food.comments')]: food.comments } : {}),
        },
        date: food.date,
        inputMethod: food.deviceType,
      };
    }
    case 'activity': {
      const activity = info.data;
      return {
        content: {
          [t('charts.data')]: t('activity.title'),
          [t('activity.intensity')]: t(
            getActivityIntensityTranslation(activity.intensity),
          ),
          [t('activity.duration')]:
            `${formatNumber(activity.duration, 0)} ${t('activity.durationUnit')}`,
        },
        date: activity.date,
        inputMethod: activity.deviceType,
      };
    }
    case 'report': {
      const report = info.data;
      return {
        content: {
          [t('charts.data')]: t('report.title'),
          [t('report.category')]: t(
            getReportCategoryTranslation(report.category),
          ),
          ...(report.message ? { [t('report.message')]: report.message } : {}),
        },
        date: report.date,
        inputMethod: report.deviceType,
      };
    }
  }
};

type AdditionalDiabetesInfoTooltipContentProps = {
  items: AdditionalDiabetesInfo[];
};

const AdditionalDiabetesInfoTooltipContent: React.FC<
  AdditionalDiabetesInfoTooltipContentProps
> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <SvgDataListTooltip
      items={items.map(item =>
        getAdditionalDiabetesInfoTooltipContent(item, t),
      )}
    />
  );
};

export type AdditionalDiabetesInfoPointTooltipProps = PropsWithChildren<{
  items: AdditionalDiabetesInfo[];
  x?: number;
  y?: number;
}>;

export const AdditionalDiabetesInfoPointTooltip: React.FC<
  AdditionalDiabetesInfoPointTooltipProps
> = ({ items, x, y, children }) => (
  <Tooltip
    svg
    placement="top"
    relativeClientPoint
    clientPoint={{
      enabled: true,
      x: x ?? 0,
      y: y ?? 0,
    }}
    content={<AdditionalDiabetesInfoTooltipContent items={items} />}
  >
    {children}
  </Tooltip>
);
