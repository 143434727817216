import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { Logo } from '@/components/navigation/Logo';
import { UserMenu } from '@/components/navigation/UserMenu';
import { useStyles } from '@/hooks/useStyles';

export type SideBarProps = PropsWithChildren<{
  home: string;
  className?: string;
}>;

export const SideBar: React.FC<SideBarProps> = ({
  home,
  className,
  children,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <nav className={cx(styles.container, className)} id="main-navbar">
      <Logo to={home} />
      <div className={styles.items} id="main-navbar-nav-items">
        {children}
      </div>
      <div className={styles.foot} id="main-navbar-footer">
        <UserMenu />
      </div>
    </nav>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: ${theme.spacing(30)};
    height: 100%;
    z-index: ${theme.zIndex.appBar};
    border-right-width: 1px;
    border: ${theme.palette.common.white} solid;
    background-color: ${theme.palette.common.white};
  `,
  items: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  foot: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing(8)};
    gap: ${theme.spacing(8)};
  `,
});
