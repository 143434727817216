import { cx } from '@emotion/css';
import { MenuItem, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropDownSelect } from '../atoms/DropDownSelect';

type DropDownChoice = {
  displayName?: string;
  translationKey?: string;
  submitValue?: string;
  value: string;
};

type DropDownProps = {
  id?: string;
  fieldName: string;
  choices: DropDownChoice[];
  disabled?: boolean;
  className?: string;
  classes?: {
    root?: string;
    dropdown?: string;
    select?: string;
    menuItem?: string;
  };
};

export const DropDown = ({
  id,
  fieldName,
  choices,
  className,
  classes,
  disabled = false,
}: DropDownProps) => {
  const { t } = useTranslation();

  return (
    <div id={id} className={cx(className, classes?.root)}>
      <Controller
        name={fieldName}
        disabled={disabled}
        rules={{ required: t('form.required') }}
        render={({ field: { value, ref, ...rest }, fieldState }) => {
          const error = fieldState?.error;
          const errorMessage =
            typeof error?.message === 'string' ? error.message : '';
          return (
            <>
              <DropDownSelect
                {...rest}
                value={value === undefined ? '' : value}
                classes={{
                  root: classes?.dropdown,
                  select: classes?.select,
                }}
                fullWidth={false}
                error={!!error}
              >
                {choices.map(choice => {
                  return (
                    <MenuItem
                      id={`${id}-${choice.value}`}
                      key={choice.value}
                      value={choice.value}
                      className={classes?.menuItem}
                    >
                      {choice.displayName
                        ? choice.displayName
                        : t(choice.translationKey as string)}
                    </MenuItem>
                  );
                })}
              </DropDownSelect>
              {error ? (
                <Typography color="error">{errorMessage}</Typography>
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
};
