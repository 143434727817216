import React from 'react';

import { css, cx } from '@emotion/css';
import { PaletteColor, Theme } from '@mui/material';
import { Group } from '@visx/group';

import { useStyles } from '@/hooks/useStyles';
import { SvgDot } from '@/uiKit/atoms/svg/SvgDot.tsx';
import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';
import { SvgLayout } from '@/utils/graphUtils.ts';

export const BADGE_HEIGHT = 24;
export const BADGE_WIDTH = 70;
export const BADGE_ICON_SIZE = 14;

export type SvgBadgeProps = {
  text: string;
  color: PaletteColor;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  dot?: boolean | number;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & Partial<SvgLayout>;

/**
 * Badge component for SVGs.
 *
 * TODO test performance to check if it should be memoized.
 */
export const SvgBadge: React.FC<SvgBadgeProps> = ({
  text,
  Icon,
  color,
  dot = false,
  className,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  top = 0,
  left = 0,
  height = BADGE_HEIGHT,
  width = BADGE_WIDTH,
}) => {
  const styles = useStyles(makeStyles, color, width, height);
  const hasDot = typeof dot === 'number' || dot;
  const dotNumber = typeof dot === 'number' ? dot : undefined;
  const dim = { top, left, width, height };
  const layout = getLayout(dim, !!Icon);

  return (
    <Group
      {...dim}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx(styles.container, className)}
    >
      <rect {...layout.badge} className={styles.badge} />
      {Icon && <Icon className={styles.icon} {...layout.icon} />}
      <SvgText
        {...layout.text}
        variant="bodySmall"
        textAnchor="middle"
        verticalAnchor="middle"
        className={styles.text}
      >
        {text}
      </SvgText>
      {hasDot && <SvgDot {...layout.dot} color={color} content={dotNumber} />}
    </Group>
  );
};

const getLayout = (dim: SvgLayout, hasIcon: boolean) => {
  const halfHeight = dim.height / 2;
  const halfWidth = dim.width / 2;
  const iconPadding = BADGE_ICON_SIZE / 2;

  return {
    badge: {
      x: -halfWidth,
      rx: halfHeight,
      ry: halfHeight,
    },
    text: {
      top: halfHeight,
      left: hasIcon ? BADGE_ICON_SIZE / 2 : 0,
    },
    icon: {
      x: -halfWidth + iconPadding,
      y: (dim.height - BADGE_ICON_SIZE) / 2,
      width: BADGE_ICON_SIZE,
      height: BADGE_ICON_SIZE,
    },
    dot: {
      left: halfWidth,
    },
  };
};

const makeStyles = (
  _: Theme,
  color: PaletteColor,
  width: number,
  height: number,
) => ({
  container: css``,
  badge: css({
    width: width,
    height: height,
    fill: color.contrastText,
  }),
  text: css({
    fill: color.dark,
  }),
  icon: css({
    color: color.dark,
  }),
});
