import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { DeviceType } from '@/models/MedicalDeviceModel.ts';
import { toDateTime } from '@/utils/dateUtils.ts';

export type SvgDataTooltipProps = {
  inputMethod?: DeviceType;
  content: Record<string, string | undefined>;
  date: string;
};

export const SvgDataTooltip: React.FC<SvgDataTooltipProps> = ({
  inputMethod,
  content,
  date,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const caption =
    date && inputMethod
      ? `${toDateTime(date).toLocaleString(DateTime.DATETIME_SHORT)} • ${t(`charts.inputMethod.${inputMethod}`)}`
      : date
        ? toDateTime(date).toLocaleString(DateTime.DATETIME_SHORT)
        : inputMethod
          ? t(`charts.inputMethod.${inputMethod}`)
          : undefined;

  return (
    <div className={styles.tooltip}>
      {Object.entries(content)
        .filter(([_, value]) => !!value)
        .map(([key, value], index) => (
          <div className={styles.item} key={key}>
            <Typography
              variant="bodySmall"
              component="span"
              className={styles.itemTitle}
            >
              {key}
            </Typography>
            <Typography key={index} variant="bodySmall" component="span">
              {value}
            </Typography>
          </div>
        ))}
      {caption ? (
        <Typography variant="caption" className={styles.caption}>
          {caption}
        </Typography>
      ) : null}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  tooltip: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(4)} 0;
  `,
  item: css`
    display: flex;
    flex-direction: row;
    max-width: ${theme.spacing(192)};
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    gap: ${theme.spacing(8)};
  `,
  itemTitle: css`
    font-weight: bold;
  `,
  caption: css`
    max-width: ${theme.spacing(192)};
    text-align: center;
  `,
  content: css`
    word-break: break-all;
    white-space: pre-wrap;
  `,
  title: css`
    font-weight: bold;
  `,
});
