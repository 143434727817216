import { ChipOwnProps } from '@mui/material';
import {
  DecoderFunction,
  array,
  boolean,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import { ConditionType, MedicationSchema } from '@/models/ConditionModel.ts';
import {
  PractitionerSummary,
  practitionerSummaryDecoder,
} from '@/models/PractitionerModel.ts';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils.ts';

export type MessageNotificationsCount = {
  fromPatient: number;
  fromPractitionerToAll: number;
  fromPractitionerToPractitioner: number;
  fromGestationalPatient: number;
};

export type TelemonitoringTag =
  | 'active'
  | 'expiring_soon'
  | 'expired'
  | 'expired_recently'
  | 'gestational_no_insulin'
  | 'no_glycemia_data';

export type RenewalSuggestion = 'expiring' | 'add_insulin';

export type PatientListItem = {
  id: string;
  givenName: string;
  familyName: string;

  doctor?: PractitionerSummary;
  messages: MessageNotificationsCount;
  diabetesType: ConditionType;
  insulinTreatmentType: MedicationSchema;
  renewalSuggestion?: RenewalSuggestion;
  hasNurseNotice: boolean;

  tags: TelemonitoringTag[];
};

export type PatientList = PatientListItem[];

export const patientListItemDecoder: DecoderFunction<PatientListItem> =
  recordWithContext('PatientListItem', {
    id: string,
    givenName: field('given_name', string),
    familyName: field('family_name', string),
    doctor: nullOrUndef(practitionerSummaryDecoder),
    messages: recordWithContext('MessageNotificationsCount', {
      fromPatient: field('sent_by_patient', number),
      fromPractitionerToAll: field('sent_by_practitioner_to_all', number),
      fromPractitionerToPractitioner: field(
        'sent_by_practitioner_to_practitioner',
        number,
      ),
      fromGestationalPatient: field('sent_by_dg_patient', number),
    }),
    renewalSuggestion: field(
      'renewal_suggestion',
      nullOrUndef(stringUnion('expiring', 'add_insulin')),
    ),
    hasNurseNotice: field('has_nurse_notice', boolean),
    diabetesType: field(
      'diabetes_type',
      stringUnion('type1', 'type2', 'gestational'),
    ),
    insulinTreatmentType: field(
      'insulin_treatment_type',
      stringUnion('insulinPen', 'insulinPump', 'noInsulin'),
    ),
    tags: field(
      'telemonitoring_tags',
      array(
        stringUnion(
          'active',
          'expiring_soon',
          'expired',
          'expired_recently',
          'gestational_no_insulin',
          'no_glycemia_data',
        ),
      ),
    ),
  });

export const telemonitoringTagColorMapping = (
  tag: TelemonitoringTag,
): ChipOwnProps['color'] => {
  switch (tag) {
    case 'active':
    case 'gestational_no_insulin':
      return 'green';
    case 'expiring_soon':
      return 'neutral';
    case 'expired':
    case 'expired_recently':
      return 'red';
    case 'no_glycemia_data':
      return 'purple';
  }
};

export const telemonitoringTagTranslationMapping = (
  tag: TelemonitoringTag,
): string => {
  switch (tag) {
    case 'active':
      return 'models.telemonitoringTag.active';
    case 'expiring_soon':
      return 'models.telemonitoringTag.expiringSoon';
    case 'expired':
      return 'models.telemonitoringTag.expired';
    case 'expired_recently':
      return 'models.telemonitoringTag.expiredRecently';
    case 'gestational_no_insulin':
      return 'models.telemonitoringTag.gestationalNoInsulin';
    case 'no_glycemia_data':
      return 'models.telemonitoringTag.onboarding';
  }
};
