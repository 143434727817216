import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { useTranslation } from 'react-i18next';

import { splitInsulinData } from '@/business/InsulinLogic.ts';
import { useStyles } from '@/hooks/useStyles';
import { InsulinDataDisplayType } from '@/models/DiabetesDisplayModeModel.ts';
import { PumpEvent } from '@/models/InsulinPumpEvents.ts';
import { Insulin } from '@/models/diabetes/InsulinModel.ts';
import { SvgLegendItem } from '@/uiKit/atoms/svg/SvgLegendItem.tsx';
import { BolusInsulinGraph } from '@/uiKit/molecules/graphs/InsulinGraph/BolusInsulinGraph.tsx';
import { ContinuousInsulinGraph } from '@/uiKit/molecules/graphs/InsulinGraph/ContinuousInsulinGraph.tsx';
import { DiscreteInsulinPoints } from '@/uiKit/molecules/graphs/InsulinGraph/DiscreteInsulinPoints.tsx';
import { InsulinLegend } from '@/uiKit/molecules/graphs/InsulinGraph/InsulinLegend.tsx';
import { SvgCoords, SvgLayout, SvgLegend } from '@/utils/graphUtils.ts';

export type InsulinGraphProps = {
  date: string;
  insulin: Insulin[];
  pumpEvents: PumpEvent[];
  insulinDisplayMode: InsulinDataDisplayType;
  className?: string;
  legend?: SvgLegend;
} & SvgLayout;

type InsulinGraphLayout = {
  legend: SvgLayout & { middle: number };
  eventArea: SvgLayout;
  fast: SvgLayout;
  slow: SvgLayout;
  bolus: SvgLayout;
  basal: SvgLayout;
  middleLine: SvgCoords;
  bottomLine: SvgCoords;
  verticalPadding: number;
};

const InsulinGraph_: React.FC<InsulinGraphProps> = ({
  date,
  insulin,
  pumpEvents,
  insulinDisplayMode,
  legend,
  className,
  ...dim
}) => {
  const styles = useStyles(makeStyles);
  const theme = useTheme();
  const { t } = useTranslation();
  const data = splitInsulinData(insulin);
  const layout = getLayout(dim, insulinDisplayMode, legend);
  const legendContentWidth =
    layout.legend.width - (legend?.legendPadding.x ?? 0) * 2;

  if (insulinDisplayMode === 'discrete') {
    return (
      <Group className={cx(styles.container, className)} {...dim}>
        {legend && (
          <InsulinLegend
            {...layout.legend}
            legendPadding={legend.legendPadding}
            topLabel={
              <SvgLegendItem
                label={t('charts.insulin.fast', {
                  context: legendContentWidth < 80 ? 'short' : '',
                })}
                unit={t('charts.insulin.fastUnit')}
              />
            }
            bottomLabel={
              <SvgLegendItem
                label={t('charts.insulin.slow', {
                  context: legendContentWidth < 80 ? 'short' : '',
                })}
                unit={t('charts.insulin.slowUnit')}
              />
            }
          />
        )}
        <DiscreteInsulinPoints
          {...layout.fast}
          date={date}
          discrete={data.bolus}
          color={theme.palette.insulin.bolus}
        />
        <Line {...layout.middleLine} className={styles.line} />
        <DiscreteInsulinPoints
          {...layout.slow}
          date={date}
          discrete={data.discrete}
          color={theme.palette.insulin.basal}
        />
        <Line {...layout.bottomLine} className={styles.line} />
      </Group>
    );
  }
  return (
    <Group className={cx(styles.container, className)} {...dim}>
      <Line {...layout.middleLine} className={styles.line} />
      <Line {...layout.bottomLine} className={styles.line} />
      {legend && (
        <InsulinLegend
          {...layout.legend}
          legendPadding={legend.legendPadding}
          topLabel={
            <>
              <SvgLegendItem
                label={t('charts.insulin.bolus')}
                unit={
                  legendContentWidth < 60 ? '' : t('charts.insulin.bolusUnit')
                }
              />
              {legendContentWidth >= 60 ? (
                <SvgLegendItem
                  top={20}
                  label={`& ${t('charts.insulin.carbs', {
                    context: legendContentWidth < 90 ? 'short' : '',
                  })}`}
                  unit={t('charts.insulin.carbsUnit')}
                />
              ) : null}
            </>
          }
          bottomLabel={
            <SvgLegendItem
              label={t('charts.insulin.basalRate', {
                context: legendContentWidth < 90 ? 'short' : '',
              })}
              unit={
                legendContentWidth < 60 ? '' : t('charts.insulin.basalRateUnit')
              }
            />
          }
        />
      )}
      <BolusInsulinGraph
        {...layout.bolus}
        date={date}
        bolus={data.bolus}
        paddingTop={layout.verticalPadding}
      />
      <ContinuousInsulinGraph
        {...layout.basal}
        date={date}
        continuous={data.continuous}
        pumpEvents={pumpEvents}
        paddingTop={layout.verticalPadding}
      />
    </Group>
  );
};

/**
 * Memoized {@link InsulinGraph_} component
 * Pure component, no need to re-render if props are the same
 * @see _InsulinGraph
 *
 */
export const InsulinGraph = React.memo(InsulinGraph_);

const getLayout = (
  dim: SvgLayout,
  insulinDisplayMode: InsulinDataDisplayType,
  legend: SvgLegend | undefined,
): InsulinGraphLayout => {
  const fastHeight = dim.height / 2;
  const slowHeight = dim.height / 2;
  const bolusHeight = dim.height * 0.7;
  const basalHeight = dim.height * 0.3;
  const graphHorizontalPadding = dim.left;
  const left = legend ? legend.legendWidth : 0;
  const width = legend ? dim.width - legend.legendWidth : dim.width;
  const contentVerticalPadding = dim.height * 0.05;
  const middle = insulinDisplayMode === 'discrete' ? fastHeight : bolusHeight;
  return {
    legend: {
      top: 0,
      left: 0,
      middle: middle,
      width: legend?.legendWidth || 0,
      height: dim.height,
    },
    eventArea: {
      top: 0,
      left,
      width,
      height: dim.height,
    },
    fast: {
      top: 0,
      left,
      width,
      height: fastHeight,
    },
    slow: {
      top: fastHeight,
      left,
      width,
      height: slowHeight,
    },
    bolus: {
      top: 0,
      left,
      width,
      height: bolusHeight,
    },
    basal: {
      top: bolusHeight,
      left,
      width,
      height: basalHeight,
    },
    middleLine: {
      from: {
        x: 0,
        y: middle,
      },
      to: {
        x: dim.width + graphHorizontalPadding,
        y: middle,
      },
    },
    bottomLine: {
      from: {
        x: 0,
        y: dim.height,
      },
      to: {
        x: dim.width + graphHorizontalPadding,
        y: dim.height,
      },
    },
    verticalPadding: contentVerticalPadding,
  };
};

const makeStyles = (theme: Theme) => ({
  container: css`
    background-color: green;
    border: 1px solid green;
  `,
  line: css({
    stroke: theme.palette.grey[100],
    strokeWidth: 1,
  }),
});
