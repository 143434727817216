import { DateTime } from 'luxon';

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource.ts';
import { pumpEventDecoder } from '@/models/InsulinPumpEvents.ts';
import { medicalDeviceDecoder } from '@/models/MedicalDeviceModel.ts';
import { insulinDecoder } from '@/models/diabetes/InsulinModel.ts';
import { forcedArray } from '@/utils/decoderUtils.ts';

export class InsulinRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getInsulin = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/insulin/${patientId}/`,
      forcedArray(insulinDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getInsulinDevices = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/insulin/${patientId}/devices/`,
      forcedArray(medicalDeviceDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };

  getInsulinPumpEvents = (patientId: string, from: DateTime, to: DateTime) => {
    return this.datasource.get(
      `/practitioner/pumps/${patientId}/events/`,
      forcedArray(pumpEventDecoder),
      {
        params: {
          start: from.toISO(),
          end: to.toISO(),
        },
      },
    );
  };
}
