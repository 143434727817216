import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Button, Card, CardContent, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import { PractitionerUser } from '@/models/UserModel.ts';
import { PageContainer } from '@/pages/PageContainer.tsx';
import { HealthProLayout } from '@/pages/layout/HealthProLayout.tsx';
import { PatientListCardContent } from '@/pages/patients/patients-list/PatientListCard.tsx';
import { CreatePatient } from '@/pages/patients/telemonitoringForms/CreatePatient.tsx';

export type PatientsPageProps = {
  me: PractitionerUser;
};

export const PatientsPage: React.FC<PatientsPageProps> = ({ me }) => {
  const { t } = useTranslation();
  const [openCreatePatient, setOpenCreatePatient] = useState<boolean>(false);

  const styles = useStyles(makeStyles);

  return (
    <HealthProLayout>
      <PageContainer
        id="patients-list-page"
        title={t('pages.patients.title')}
        content={
          me.qualification === 'doctor' ? (
            <Button
              id="create-patient-button"
              className={styles.createPatient}
              variant="contained"
              startIcon={<Icons.userPlus />}
              onClick={() => setOpenCreatePatient(true)}
            >
              {t('pages.patients.addPatient')}
            </Button>
          ) : null
        }
        className={styles.container}
      >
        <Card elevation={0}>
          <CardContent className={styles.content}>
            <PatientListCardContent me={me} />
          </CardContent>
        </Card>
        {openCreatePatient ? (
          <CreatePatient close={() => setOpenCreatePatient(false)} />
        ) : null}
      </PageContainer>
    </HealthProLayout>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};

    padding: 4vh 4vw;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(12, 16)};
  `,
  createPatient: css`
    width: fit-content;
    justify-self: flex-end;
  `,
});
