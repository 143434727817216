import React, { useState } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { ChatContainer } from '@/components/chat/ChatContainer.tsx';
import { ChatStatusContext } from '@/components/chat/ChatContext';
import { PatientNavigation } from '@/components/navigation/PatientNavigation';
import { useStyles } from '@/hooks/useStyles';
import { PractitionerUser } from '@/models/UserModel.ts';
import { HealthProLayout } from '@/pages/layout/HealthProLayout.tsx';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';

export type PractitionerManagePatientLayoutProps = LayoutProps & {
  me: PractitionerUser;
};

export const PractitionerManagePatientLayout: React.FC<
  PractitionerManagePatientLayoutProps
> = props => {
  const styles = useStyles(makeStyles);
  const [chatState, setChatState] = useState(false);
  return (
    <HealthProLayout>
      <ChatStatusContext.Provider
        // TODO use zustand instead
        value={{ open: chatState, setOpen: setChatState }}
      >
        {chatState && <ChatContainer />}
        <PatientNavigation className={styles.header} />
        <section id="manage-patient-layout-main" className={styles.main}>
          <RenderLayoutChildren {...props} />
        </section>
      </ChatStatusContext.Provider>
    </HealthProLayout>
  );
};

const makeStyles = (theme: Theme) => ({
  header: css``,
  main: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(12)} 4vw;
  `,
});
