import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { useScreenSize } from '@/theme/responsive.ts';
import { toDateTime } from '@/utils/dateUtils.ts';

type DateInCellProps = {
  date: string;
};

export const PrettyDate = ({ date }: DateInCellProps) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const screenSize = useScreenSize();

  if (date.includes('avg')) {
    return (
      <div className={styles.container}>
        <Typography variant="body" className={styles.date}>
          {t('bgmLogBook.average')}
        </Typography>
      </div>
    );
  }
  const dt = toDateTime(date);
  const day =
    screenSize === 'SM'
      ? dt.toFormat('EEE').capitalize()
      : dt.toFormat('EEEE').capitalize();
  const completeDate =
    screenSize === 'SM' ? dt.toFormat('dd/MM') : dt.toFormat('dd/MM/yy');

  return (
    <div className={styles.container}>
      <Typography variant="body" className={styles.date}>
        {day}
      </Typography>
      <Typography variant="body" className={styles.date}>
        {completeDate}
      </Typography>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  date: css`
    color: ${theme.palette.violet.main};
  `,
  container: css`
    margin: auto;
    display: flex;
    flex-direction: column;
  `,
});
