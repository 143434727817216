import React, { useMemo } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';
import { curveStepBefore } from '@visx/curve';
import { Group } from '@visx/group';
import { AreaClosed, Circle, Line, LinePath } from '@visx/shape';

import { useMousePosition } from '@/hooks/useMousePosition.ts';
import { useStyles } from '@/hooks/useStyles';
import { PumpEvent } from '@/models/InsulinPumpEvents.ts';
import { ContinuousBasalInsulin } from '@/models/diabetes/InsulinModel.ts';
import { SvgDot } from '@/uiKit/atoms/svg/SvgDot.tsx';
import { SvgText } from '@/uiKit/atoms/svg/SvgText.tsx';
import { GraphAreaListener } from '@/uiKit/molecules/graphs/GraphEvents/GraphAreaListener.tsx';
import {
  InsulinGraphTooltip,
  PumpEventTooltip,
} from '@/uiKit/molecules/graphs/InsulinGraph/InsulinTooltips.tsx';
import { toDateTime } from '@/utils/dateUtils.ts';
import {
  SvgCoords,
  SvgLayout,
  SvgPoint,
  boundedDateTimeToSecond,
  dayScale,
  findSurroundingPoints,
  selectSurroundingPoint,
  splitGapsInData,
  zeroScale,
} from '@/utils/graphUtils.ts';
import { formatNumber } from '@/utils/mathUtils.ts';

export const MAX_BASAL_RATE = 1;
export const PUMP_EVENT_RADIUS = 10;

export type ContinuousInsulinGraphProps = {
  date: string;
  continuous: ContinuousBasalInsulin[];
  pumpEvents: PumpEvent[];
  className?: string;
  paddingTop?: number;
} & SvgLayout;

type ContinuousInsulinGraphLayout = {
  container: SvgLayout;
  mouseLine: (point: SvgPoint) => SvgCoords;
  graphArea: SvgLayout;
  eventArea: SvgLayout;
};

const pumpEventDisplayName = {
  tubing_filled: 'T',
  cannula_filled: 'C',
  cartridge_filled: 'R',
} satisfies Record<PumpEvent['category'], string>;

const ContinuousInsulinGraph_: React.FC<ContinuousInsulinGraphProps> = ({
  date,
  continuous,
  pumpEvents,
  className,
  paddingTop = 0,
  left,
  top,
  width,
  height,
}) => {
  const styles = useStyles(makeStyles);
  const { onMouseLeave, onMouseMove, position } = useMousePosition();
  const layout = useMemo(
    () => getLayout({ left, top, width, height }, paddingTop),
    [left, top, width, height, paddingTop],
  );
  const theme = useTheme();
  const xScale = dayScale(layout.graphArea.width);
  const maxBasal = Math.max(
    ...continuous.map(ins => ins.quantity),
    MAX_BASAL_RATE,
  );

  const yScale = zeroScale(layout.graphArea.height, maxBasal);
  const scaledMaxBasal = yScale(maxBasal);

  const day = toDateTime(date);

  // Convert data to x, y coordinates
  const coordData = continuous.map(ins => ({
    data: ins,
    x: xScale(boundedDateTimeToSecond(day, ins.date)),
    y: yScale(ins.quantity),
  }));

  const surroundingPoints = findSurroundingPoints(coordData, position?.x);
  const selectedPoint = selectSurroundingPoint(surroundingPoints, {
    rule: 'closest',
    maxDistance: xScale(15 * 60),
  });

  // Split data at gaps
  let splitData = splitGapsInData(coordData, xScale(30 * 60));

  // Scale data to graph dimensions
  splitData = splitData.map(line =>
    line.map(point => ({
      x: point.x,
      y: point.y,
    })),
  );

  return (
    <Group className={cx(styles.container, className)} {...layout.container}>
      <Group {...layout.graphArea}>
        <SvgText
          variant="captionSmall"
          left={-6}
          top={scaledMaxBasal - 3}
          textAnchor={'end'}
          className={styles.maxBasal}
        >
          {formatNumber(maxBasal, 1)}
        </SvgText>
        <Line
          x1={0}
          x2={-4}
          y1={scaledMaxBasal}
          y2={scaledMaxBasal}
          className={styles.maxBasalLine}
        />
        {splitData.map(line => (
          <Group key={`${line?.[0].x}`}>
            <AreaClosed
              className={styles.continuousArea}
              curve={curveStepBefore}
              data={line}
              x={d => d.x}
              y={d => d.y}
              yScale={yScale}
            />
            <LinePath
              className={styles.continuousLine}
              data={line}
              x={d => d.x}
              y={d => d.y}
              curve={curveStepBefore}
            />
          </Group>
        ))}
        {selectedPoint && (
          <InsulinGraphTooltip insulin={selectedPoint.data}>
            <Line
              {...layout.mouseLine(selectedPoint)}
              className={styles.mouseLine}
            />
            <Circle
              className={styles.selectedPoint}
              cx={selectedPoint.x}
              cy={selectedPoint.y}
              r={4}
            />
          </InsulinGraphTooltip>
        )}
      </Group>
      <GraphAreaListener
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        {...layout.eventArea}
      />
      {pumpEvents.map(event => {
        const left = xScale(boundedDateTimeToSecond(day, event.date));
        return (
          <PumpEventTooltip key={event.id} pumpEvent={event}>
            <SvgDot
              top={paddingTop + PUMP_EVENT_RADIUS}
              left={left}
              color={theme.palette.yellow}
              radius={PUMP_EVENT_RADIUS}
              content={pumpEventDisplayName[event.category]}
            />
          </PumpEventTooltip>
        );
      })}
    </Group>
  );
};

export const ContinuousInsulinGraph = React.memo(ContinuousInsulinGraph_);

const getLayout = (
  dim: SvgLayout,
  verticalPadding: number,
): ContinuousInsulinGraphLayout => {
  return {
    container: dim,
    mouseLine: point => ({
      from: { x: point.x, y: -verticalPadding },
      to: { x: point.x, y: dim.height - verticalPadding },
    }),
    graphArea: {
      top: verticalPadding,
      left: 0,
      width: dim.width,
      height: dim.height - verticalPadding,
    },
    eventArea: {
      top: 0,
      left: 0,
      width: dim.width,
      height: dim.height,
    },
  };
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  maxLine: css({
    stroke: theme.palette.grey[200],
    strokeDasharray: '6, 3',
  }),
  continuousLine: css({
    stroke: theme.palette.insulin.basal.light,
    strokeWidth: 2,
  }),
  continuousArea: css({
    fill: theme.palette.insulin.basal.contrastText,
    stroke: 'transparent',
  }),
  continuousInsulinText: css({
    textAnchor: 'end',
    stroke: 'transparent',
    fill: theme.palette.insulin.basal.main,
  }),
  mouseLine: css({
    fill: theme.palette.grey[300],
    stroke: theme.palette.grey[300],
    strokeWidth: 1,
  }),
  selectedPoint: css({
    color: theme.palette.insulin.basal.light,
    fill: 'currentColor',
    stroke: 'currentColor',
  }),
  maxBasal: css`
    fill: ${theme.palette.insulin.basal.main};
    stroke: transparent;
  `,
  maxBasalLine: css`
    stroke: ${theme.palette.insulin.basal.main};
    stroke-width: 0.5;
  `,
});
