import React, { PropsWithChildren, useMemo } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/components/floating/Tooltip.tsx';
import {
  PumpEvent,
  getPumpEventTranslation,
} from '@/models/InsulinPumpEvents.ts';
import {
  BolusInsulin,
  Insulin,
  getInsulinReasonTranslation,
  getInsulinTypeTranslation,
} from '@/models/diabetes/InsulinModel.ts';
import { SvgDataListTooltip } from '@/uiKit/atoms/svg/SvgDataListTooltip';
import { SvgDataTooltip } from '@/uiKit/atoms/svg/SvgDataTooltip.tsx';
import { formatNumber } from '@/utils/mathUtils.ts';

const InsulinTooltipContent: React.FC<{ items: Insulin[] }> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <SvgDataListTooltip
      items={items.map(insulin => {
        return {
          content: {
            [t('charts.data')]: t('insulin.title'),
            [t('insulin.type')]: t(getInsulinTypeTranslation(insulin.type), {
              context: 'short',
            }),
            [t('insulin.reason')]: t(
              getInsulinReasonTranslation(insulin.reason),
              { context: 'short' },
            ),
            [t('insulin.quantity')]: `${formatNumber(insulin.quantity, 1)} U`,
            ...(insulin.reason === 'bolus'
              ? getBolusContent(t, insulin as BolusInsulin)
              : {}),
          },
          date: insulin.date,
          inputMethod: insulin.deviceType,
        };
      })}
    />
  );
};

export type InsulinGraphTooltipProps = PropsWithChildren<{
  insulin: Insulin;
}>;

export const InsulinGraphTooltip: React.FC<InsulinGraphTooltipProps> = ({
  insulin,
  children,
}) => (
  <Tooltip
    svg
    placement="top"
    dismiss={false}
    autoOpen
    offset={0}
    content={<InsulinTooltipContent items={[insulin]} />}
  >
    {children}
  </Tooltip>
);

export type InsulinPointTooltipProps = PropsWithChildren<{
  insulins: Insulin[];
  x?: number;
  y?: number;
}>;

export const InsulinPointTooltip: React.FC<InsulinPointTooltipProps> = ({
  insulins,
  x,
  y,
  children,
}) => (
  <Tooltip
    svg
    placement="top"
    relativeClientPoint
    clientPoint={{
      enabled: true,
      x: x ?? 0,
      y: y ?? 0,
    }}
    content={<InsulinTooltipContent items={insulins} />}
  >
    {children}
  </Tooltip>
);

const getBolusContent = (t: TFunction, insulin: BolusInsulin) => {
  let result = {};
  if (!insulin.bolusSettings || insulin.bolusSettings.type === 'manual') {
    return result;
  }
  if (
    insulin.bolusSettings.type === 'carbs' ||
    insulin.bolusSettings.type === 'carbs_and_correction'
  ) {
    result = {
      ...result,
      [t('insulin.foodInsulin')]:
        `${formatNumber(insulin.bolusSettings.foodInsulin, 1)} U`,
      [t('insulin.carbs')]:
        insulin.bolusSettings.carbs &&
        `${formatNumber(insulin.bolusSettings.carbs, 1)} g`,
      [t('insulin.carbsRatio')]:
        insulin.bolusSettings.carbsRatio &&
        `${formatNumber(insulin.bolusSettings.carbsRatio, 1)} g/U`,
    };
  }
  if (
    insulin.bolusSettings.type === 'correction' ||
    insulin.bolusSettings.type === 'carbs_and_correction'
  ) {
    result = {
      ...result,
      [t('insulin.correction')]:
        `${formatNumber(insulin.bolusSettings.correctionInsulin, 1)} U`,
      [t('insulin.glycemia')]:
        insulin.bolusSettings.glycemia &&
        `${formatNumber(insulin.bolusSettings.glycemia, 0)} mg/dL`,
      [t('insulin.insulinSensitivity')]:
        insulin.bolusSettings.insulinSensitivity &&
        `${formatNumber(insulin.bolusSettings.insulinSensitivity, 1)} mg/dL/U`,
      [t('insulin.targetGlycemia')]:
        insulin.bolusSettings.targetGlycemia &&
        `${formatNumber(insulin.bolusSettings.targetGlycemia, 0)} mg/dL`,
    };
  }
  result = {
    ...result,
    [t('insulin.iob')]:
      insulin.bolusSettings.iob &&
      `${formatNumber(insulin.bolusSettings.iob, 1)} U`,
  };
  return result;
};

export type PumpEventTooltipProps = PropsWithChildren<{
  pumpEvent: PumpEvent;
  x?: number;
  y?: number;
}>;

export const PumpEventTooltip: React.FC<PumpEventTooltipProps> = ({
  pumpEvent,
  x,
  y,
  children,
}) => {
  const { t } = useTranslation();
  const content = useMemo(() => {
    switch (pumpEvent.category) {
      case 'cartridge_filled':
        return {
          [t('pumpEvents.insulin')]: pumpEvent.insulinActual
            ? `${formatNumber(pumpEvent.insulinActual, 1)} U`
            : undefined,
        };
      case 'cannula_filled':
      case 'tubing_filled':
        return {
          [t('pumpEvents.primeSize')]: pumpEvent.primeSize
            ? `${formatNumber(pumpEvent.primeSize, 1)} U`
            : undefined,
        };
    }
  }, [pumpEvent]);

  return (
    <Tooltip
      svg
      placement="top"
      relativeClientPoint
      clientPoint={{
        enabled: true,
        x: x ?? 0,
        y: y ?? 0,
      }}
      content={
        <SvgDataTooltip
          date={pumpEvent.date}
          content={{
            [t('charts.data')]: t('pumpEvents.title'),
            [t('pumpEvents.category')]: t(
              getPumpEventTranslation(pumpEvent.category),
            ),
            ...content,
          }}
        />
      }
    >
      {children}
    </Tooltip>
  );
};
