import React, { useContext } from 'react';

import { css } from '@emotion/css';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ChatFilterContext } from '@/components/chat/ChatContext';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';

export const ChatFilter: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const filterContext = useContext(ChatFilterContext);
  const patientId = usePatientIdFromURL() ?? '';
  const { data: condition } = Queries.condition.useCondition(patientId);

  const filters = [
    {
      label: t('messaging.all'),
      name: 'filterOption',
      value: 'all',
      checked: true,
    },
  ];

  condition?.isBillable &&
    filters.push({
      label: t('messaging.medicalStaff'),
      name: 'filterOption',
      value: 'practitioners',
      checked: false,
    });

  return (
    <Tabs
      value={filterContext.filter}
      className={styles.container}
      onChange={(_, value) => filterContext.setFilter?.(value)}
      variant="scrollable"
      scrollButtons="auto"
    >
      {filters.map(filter => (
        <Tab key={filter.value} label={filter.label} value={filter.value} />
      ))}
    </Tabs>
  );
};

const makeStyles = () => ({
  container: css`
    align-items: center;
  `,
});
